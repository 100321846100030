import { injectable } from "inversify";
import { StorageResponse } from "./StorageResponse";
import { HttpResponse } from './Messages/HttpResponse';
import { MessagePackage } from './Messages/MessagePackage';
import { IMessage } from './Messages/IMessage';
import { ISender } from './Messages/ISender';

/*
    SAVE AS RAW TEXT ?
    READ AS JSON ?


    SAMPLE POST:

    {
        "Sender": { "Id": "guid" },
        "Message": {
            "Name": "Update",
            "Body": {
                "Id": "number"
                "Content": { "foo": "bar" },
                "CreateIfNotExist": false
            }
        }
    }
*/


@injectable()
export class OnlineStorage
{
    private serverUrl = "https://specteam.pl/orders";
    private user: ISender | undefined;

    public SetUser(user: ISender)
    {
        this.user = user;
    }

    public async SendMessage(message: IMessage): Promise<StorageResponse>
    {
        let messagePackage = new MessagePackage();
        messagePackage.Message = message;
        messagePackage.Sender = this.user || "Anonymous";

        return await this.SendMessagePackage(messagePackage);
    }

    private async PostJSON(data): Promise<HttpResponse>
    {
        const httpResponse = new HttpResponse();
        const dataAsString = JSON.stringify(data);
        const response = await fetch(this.serverUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: dataAsString,
        });

        httpResponse.status = response.status;
        const json = await response.json();
        httpResponse.data = json;
        return httpResponse;
    }

    private async SendMessagePackage(messagePackage: MessagePackage): Promise<StorageResponse>
    {
        try
        {
            // console.log(`[OnlineStorage] ${JSON.stringify(messagePackage)} --> ${this.serverUrl}`);

            const response = await this.PostJSON(messagePackage);

            
            if (response.status == 200 && response.data.IsSuccess == true)
            {
                const result = response.data as StorageResponse;
                
                // console.log(`[OnlineStorage] ✅ Result: ${JSON.stringify(result.Result)}`);
                
                return StorageResponse.Success(result.Result);
            }
            
            console.log(`[OnlineStorage] ❌ Response: ${JSON.stringify(response)}`);
            return StorageResponse.Fault(response.status, "Non-200 Status");
        }
        catch (ex: any)
        {
            console.log(`[OnlineStorage] ❌ Exception: ${ex.message} | HTTP ${ex.response?.status}`);
            return StorageResponse.Fault(ex.response?.status, ex.message);
        }
    }
}
