import { Order } from "../Models/Order";
import { OrderStep } from "../Models/OrderStep";
import { CustomerStep } from "./CustomerStep";

export enum RejectionReason
{
    Unset = "Unset",
    NoReason = "NoReason",
    Canceled = "Canceled",
    NoCustomerActivity = "NoCustomerActivity",
    DateTooDistant = "DateTooDistant",
    UnknownDate = "UnknownDate",
    NoEquipment = "NoEquipment",
    CustomerBanned = "CustomerBanned",
    CustomerResigned = "CustomerResigned"
}


export class Step0_Rejected extends CustomerStep
{
    constructor(order: Order)
    {
        super(order, OrderStep.Rejected, "rejected.png", "Odrzucenie");

        if (order.Rejection.Reason == RejectionReason.UnknownDate)
        {
            this.Label("Wstrzymanie")
            this.Todo("Czekamy na wyznaczenie nowej daty")
        }
        
        if (order.Rejection.Reason == RejectionReason.DateTooDistant)
        {
            this.Label("Wstrzymanie")
            this.Todo("Zgłoś się bliżej daty")
        }
    }
}
