import { Collection, Div, Ref, RefBool, RefNumber, RefString, Span } from "@tblabs/truffle";
import moment from "moment";
import { ChatMessage } from "../Components/ChatMessage";
import { DateTimeProvider } from "../Services/DateTimeProvider";
import { PickupMethod } from "./PickupMethod";
import { ReturnMethod } from "./ReturnMethod";
import { OrderStep } from "./OrderStep";
import { RejectionReason } from "../Steps/Step0_Rejected";

export class OrderDeposit
{
    public PaymentDeadline = new Ref<Date>(new Date(0))
    public Value = new Ref<number>(300)
    public ToReturn = new Ref<number>(this.Value.value)
    public PaymentMoment = new Ref<Date>(new Date(0));
    public ReturnMoment = new Ref<Date>(new Date(0));
    public Account = new Ref<string>("");

    public get PaymentDeadlineString()
    {
        return moment(this.PaymentDeadline.value).format('DD.MM.YYYY')
    }
    public get ReturnMomentString()
    {
        return moment(this.ReturnMoment.value).format(`DD.MM.YYYY`)
    }
    public get IsReturned()
    {
        return this.ReturnMoment.IsSet;
    }
}

export class OrderBasket
{
    public ProductCode = "";
    public ItemsCount = 1;
    public Prices = "";

    public get AsString(): string
    {
		const productsMapping = {
            "TX": 'Kamera w guziku z mikrosłuchawką',
            "TY": 'Kamera 3K w guziku z mikrosłuchawką',
            "TS": 'Niewykrywalna kamera',
            "TM": 'Kamera z modemem',
            "M": 'Mikrosłuchawka bezprzewodowa z pętlą Bluetooth',
            "N": 'Mikrosłuchawka bezprzewodowa Nano',
            "BT": 'Pętla indukcyjna Bluetooth',
            "RV": 'Rejestrator dźwięku i obrazu z kamerą w guziku',
            "RG": 'Kamera w okularach',
            "RP": 'Kamera w długopisie',
            "RA": 'Dyktafon - rejestrator dźwięku',
            "DP": 'Wykrywacz podsłuchów',
            "GPS": 'Lokalizator GPS z telefonem',
            "PS": 'Standardowy zestaw podsłuchowy',
            "PG": 'Podsłuch GSM o zasięgu globalnym',
            "KF": 'Krótkofalówki'
          };
          
        const basket = productsMapping[this.ProductCode] || this.ProductCode;

        if (this.ItemsCount > 1)
            return `${basket} ×${this.ItemsCount}`;
        else
            return basket;
    }
}

export class OrderChat
{
    public Messages = new Collection<ChatMessage>();

    public AddMessage(who: "Customer" | "Info", msg: string)
    {
        const lastMessage = this.Messages.Items[this.Messages.Items.length - 1]
        if (lastMessage?.Message.value == msg)
        {
            return;
        }

        this.Messages.Add(new ChatMessage(who, msg))
    }
}

export class OrderCustomer
{
    public Name = new Ref<string>("");
    public Address = new Ref<string>("");
    public Email = new Ref<string>("");
    public Phone = new Ref<string>("")
    public Experience = new Ref<string>("")
}

export class OrderTimeline
{
    // Declared usage
    public From = new Ref<Date>(moment(DateTimeProvider.Now).add(5, 'days').toDate());
    public To = new Ref<Date>(moment(DateTimeProvider.Now).add(8, 'days').toDate());
    public get Length(): number
    {
        return moment(this.To.value).diff(this.From.value, 'days');
    }

    public get UsageTimeout()
    {
        return this.DaysLeft < 0;
    }
    public get InUsage()
    {
        return this.DaysLeft >= 0;
    }
    public get FromString()
    {
        return moment(this.From.value).format("DD.MM")
    }
    public get ToString()
    {
        return moment(this.To.value).format("DD.MM");
    }
    public get FromToString()
    {
        return `${this.FromString} - ${this.ToString}`;
    }
    public get ToStringFull()
    {
        return moment(this.To.value).format("DD.MM.YYYY");
    }
    public get DaysLeft(): number
    {
        return moment(this.To.value).diff(DateTimeProvider.Now, 'days');
    }
    public get DaysAfterEnd(): number
    {
        return -this.DaysLeft;
    }
    public get DaysLeftString(): string
    {
        if (this.DaysLeft < 0)
            return `Czas minął`;
        if (this.DaysLeft == 0)
            return `Ostatni dzień`;
        if (this.DaysLeft == 1)
            return `Pozostał 1 dzień`;
        if (this.DaysLeft >= 5)
            return `Pozostało ${this.DaysLeft} dni`
        if (this.DaysLeft > 1)
            return `Pozostały ${this.DaysLeft} dni`

        return this.DaysLeft.toString()
    }
}

export class OrderDelivery 
{
    public Method = new Ref<PickupMethod>(PickupMethod.Package);
    public PlanedDispatch = new Ref<Date>(new Date(0))
    public DispatchTime = new Ref<Date>(new Date(0))
    public DeliveryTime = new Ref<Date>(new Date(0)) // only for packages
    public TrainingDone = new RefBool();
    public DeviceId = new RefString();
    public CustomerComment = new RefString();

    public get PickupIcon()
    {
        return this.Method.Is(PickupMethod.Package) ? "📦" : "🐤";
    }
    public get PackageIsReadyToSend()
    {
        return this.Method.Is(PickupMethod.Package) && this.PlanedDispatch.IsSet;
    }
    public get PackageIsNotReadyToSend()
    {
        return this.Method.Is(PickupMethod.Package) && !this.PlanedDispatch.IsSet;
    }
    public get PlannedDispatchString()
    {
        return moment(this.PlanedDispatch.value).format('DD.MM.YYYY')
    }
    public get DaysToPlanedDispatch(): number
    {
        return moment(this.PlanedDispatch.value).diff(DateTimeProvider.Now, 'days')
    }
    public get DaysToDispatchString(): string
    {
        if (this.DaysToPlanedDispatch < -1)
            return `${-this.DaysToPlanedDispatch} dni temu`;
        if (this.DaysToPlanedDispatch == -1)
            return `wczoraj`;
        if (this.DaysToPlanedDispatch == 0)
            return `dzisiaj`;
        if (this.DaysToPlanedDispatch == 1)
            return `jutro`;
        if (this.DaysToPlanedDispatch > 1)
            return `za ${this.DaysToPlanedDispatch} dni`

        return "...";
    }
    public get DispatchTimeString()
    {
        return moment(this.DispatchTime.value).format('DD.MM');
    }
    public get IsDispatched()
    {
        return this.DispatchTime.IsSet;
    }
    public get IsDelivered()
    {
        return this.DeliveryTime.IsSet;
    }
}

export class OrderMana
{
    public RentCost = new RefNumber(0)
    public Costs = new RefNumber(0)
    public Prices = "";
    public ExtraCosts = new RefString();
    public DiscountCode = new RefString();

    public get Profit()
    {
        return this.RentCost.value - this.Costs.value;
    }
}

export class OrderMeta
{
    public Updated = new Date(0);
    public Created = new Date(0);
    public get HowLongAgoCreated(): string
    {
        const createdDaysAgo = moment(DateTimeProvider.Now).diff(this.Created, 'days');

        if (createdDaysAgo > 0)
            return `${createdDaysAgo} dni temu`;

        const createdHoursAgo = moment(DateTimeProvider.Now).diff(this.Created, 'hours');

        if (createdHoursAgo > 0)
            return `${createdHoursAgo} godzin temu`;

        const createdMinutesAgo = moment(DateTimeProvider.Now).diff(this.Created, 'minutes');

        if (createdMinutesAgo > 0)
            return `${createdMinutesAgo} minut temu`;
        else return `Przed chwilą`;
    }
}

export enum State
{
    Unset = "unset",
    Ok = "ok",
    Incomplete = "incomplete",
    Damaged = "damaged",
    Dirty = "dirty",
    Other = "other"
}

export class OrderEnd
{
    public Rate = new Ref<number>(0);
    public State = new Ref<State>(State.Unset);
    public StateComment = new Ref<string>("");
    public Comment = new Ref<string>("");
}

export class OrderRejection
{
    public Reason: RejectionReason = RejectionReason.Unset;

    public get IsPostponed(): boolean
    {
        return this.Reason == RejectionReason.UnknownDate || this.Reason == RejectionReason.DateTooDistant;
    }
}

export class OrderReturn
{
    public Method = new Ref<ReturnMethod>(ReturnMethod.Package);
    public ReturnTime = new Ref<Date>(new Date(0))
    public PartialReturnTime = new Ref<Date>(new Date(0))
    public DeclaredPackageReturn = new Ref<Date>(new Date(0)) // only for packages

    public get DeclaredPackageSendBackString()
    {
        return moment(this.DeclaredPackageReturn.value).format("DD.MM")
    }
    public get DaysSinceSendBack(): number
    {
        return moment(DateTimeProvider.Now).diff(this.DeclaredPackageReturn.value, 'days')
    }
    public get IsReturned()
    {
        return this.ReturnTime.IsSet;
    }
    public get IsReturnDeclared()
    {
        return this.DeclaredPackageReturn.IsSet;
    }
    public get Icon()
    {
        return this.Method.Is(ReturnMethod.Package) ? "📦" : "🐤";
    }
}


export enum OrderType
{
    Unset = "unset",
    Rental = "rental",
    Sale = "sale",
    Talk = "talk",
    Service = "service",
}

export class Order
{
    public get TypeAsString()
    {
        switch (this.Type)
        {
            case OrderType.Rental: return "Wypożyczenie";
            case OrderType.Sale: return "Zakup";
            case OrderType.Service: return "Serwis";
            default:
            case OrderType.Talk: return "Rozmowa na temat";
        }
    }

    public Id = "";
    public Type = OrderType.Unset;
    public Step = new Ref<OrderStep>(OrderStep.New)

    public Rejection = new OrderRejection();
    public Basket = new OrderBasket();
    public Timeline = new OrderTimeline();
    public Delivery = new OrderDelivery();
    public Return = new OrderReturn();
    public Deposit = new OrderDeposit();
    // public Customer = new OrderCustomer();
    public Chat = new OrderChat();
    // public Mana = new OrderMana();
    // public Meta = new OrderMeta();
    public End = new OrderEnd();
    // public Comment = new RefString();

    constructor(id?: string, pickup?: PickupMethod, returnMethod?: ReturnMethod)
    {
        if (id)
            this.Id = id;
        if (pickup)
            this.Delivery.Method.value = pickup;
        if (returnMethod)
            this.Return.Method.value = returnMethod;
    }

    private GetDate(date: string): Date
    {
        return date ? new Date(date) : new Date(0);
    }

    public FromPlainObject(rawOrder: any): this
    {
        try
        {
            this.Id = rawOrder.Id || "(no Id)";
            this.Type = rawOrder.Type || OrderType.Unset;
            this.Step.value = rawOrder.Step || OrderStep.New;
         
            // Rejection
            this.Rejection.Reason = rawOrder.Rejection?.Reason || RejectionReason.Unset;

            // Basket
            this.Basket.ProductCode = rawOrder.Basket?.ProductCode || "";
            this.Basket.ItemsCount = +rawOrder.Basket?.ItemsCount || 0;
            this.Basket.Prices = rawOrder.Basket?.Prices || "(brak cennika)";

            // Timeline
            if (this.Type == OrderType.Rental)
            {
                this.Timeline.From.value = this.GetDate(rawOrder.Timeline.From);
                this.Timeline.To.value = this.GetDate(rawOrder.Timeline.To);
            }

            // Delivery
            this.Delivery.Method.value = rawOrder.Delivery?.Method || PickupMethod.Unset;
            this.Delivery.PlanedDispatch.value = this.GetDate(rawOrder.Delivery?.PlanedDispatch);
            this.Delivery.DispatchTime.value = this.GetDate(rawOrder.Delivery?.DispatchTime);
            this.Delivery.DeliveryTime.value = this.GetDate(rawOrder.Delivery?.DeliveryTime);
            this.Delivery.CustomerComment.value = rawOrder.Delivery?.CustomerComment || "";
         
            // Return
            this.Return.Method.value = rawOrder.Return?.Method || ReturnMethod.Unset;
            this.Return.DeclaredPackageReturn.value = this.GetDate(rawOrder.Return?.DeclaredPackageReturn);
            this.Return.ReturnTime.value = this.GetDate(rawOrder.Return?.ReturnTime);

            // Deposit
            this.Deposit.PaymentDeadline.value = this.GetDate(rawOrder.Deposit?.PaymentDeadline);
            this.Deposit.PaymentMoment.value = this.GetDate(rawOrder.Deposit?.PaymentMoment);
            this.Deposit.ReturnMoment.value = this.GetDate(rawOrder.Deposit?.ReturnMoment);
            this.Deposit.Account.value = rawOrder.Deposit?.DepositAccount || "";

            // Chat
            this.Chat.Messages.Load(...rawOrder.Chat.Messages.map(x => new ChatMessage(x.Who, x.Message, new Date(x.When))));

            // End
            this.End.Rate.value = +rawOrder.End?.Rate;
            this.End.State.value = rawOrder.End?.State;
            this.End.StateComment.value = rawOrder.End?.StateComment;
            this.End.Comment.value = rawOrder.End?.Comment;
        }
        catch (ex: any)
        {
            console.log(`Converting from raw problem:`, ex.message)
        }

        return this;
    }

    public get IsConfirmed(): boolean
    {
        return this.Step.value != OrderStep.New;
    }
}
