import { Div, MultilineInput, RefString } from "@tblabs/truffle";
import { ICustomerRepo } from "../Services/Repo/ICustomerRepo";
import { Order } from '../Models/Order';
import { DangerButton } from './DangerButton';


export class MessageBoxInput extends Div
{
    constructor(_repo: ICustomerRepo, order: Order)
    {
        super();
        this.Class("MessageBox").Class("Customer").Padding(8).WidthPercent(98)

        const msg = new RefString();

        this.Append(
            new MultilineInput(msg)
                .Rows(4).Placeholder("Napisz tutaj jeśli chcesz cokolwiek zmienić w zamówieniu lub porozmawiać z kimś z obsługi").Class("user-input"),
            new Div().TextAlignLeft().MarginTop(4)
                .Append(
                    new Div().Text(`🙂 Ty`).MarginTop(8).Italic().FloatLeft(),
                    new DangerButton("Wyślij ▶")
                        .EnableWhen(msg, v => (v as string).trim().length > 0)
                        .OnClick(async (btn) =>
                        {
                            btn.Label("Wysyłanie...");
                            const result = await _repo.AddMessage(order.Id, "Customer", msg.value);
                            if (result)
                            {
                                order.Chat.AddMessage("Customer", msg.value);
                                btn.Label("Wyślij ▶");
                            }
                            else btn.Label("⚠️ Błąd wysyłania");
                        })
                        .FloatRight().BorderRadius(4).Margin(0).MarginTop(4),
                    new Div().ClearBoth()
                )
        );
    }
}
