import 'reflect-metadata';
import { Div } from "@tblabs/truffle";
import { Container, decorate, injectable } from "inversify";
import { Main } from "./Main";
import { OnlineStorage } from './Services/Storage/OnlineStorage';
import { CustomerOrderRepo } from './Services/Repo/CustomerOrderRepo';
import { CustomerRouter } from './Services/CustomerRouter';
import { OrderPage } from './Pages/OrderPage';
import { CustomerMessagesBox } from './Components/MessagesBox';


export const IoC = new Container();

decorate(injectable(), Div);
IoC.bind(Main).toSelf()
IoC.bind(OnlineStorage).toSelf().inSingletonScope()
IoC.bind("ICustomerRepo").to(CustomerOrderRepo).inSingletonScope()
IoC.bind(CustomerRouter).toSelf().inSingletonScope()
IoC.bind(OrderPage).toSelf()
IoC.bind(CustomerMessagesBox).toSelf()
