import { Order } from "../Models/Order";
import { OrderStep } from "../Models/OrderStep";
import { CustomerStep } from "./CustomerStep";


export class Step3_PackageDelivery extends CustomerStep
{
    constructor(order: Order)
    {
        super(order, OrderStep.PackageDelivery, "delivery.png", "Wysyłka");

        if (order.Delivery.IsDelivered)
        {
            this.Label(`Paczka dostarczona ✅`)
        }
        else if (order.Delivery.IsDispatched)
        {
            this.Label(`Paczka w drodze`)
                .Todo(`Oczekuj paczki`)
        }
        else if (order.Delivery.PlanedDispatch.IsSet)
        {
            this.Label(`Wysyłka ${order.Delivery.PlannedDispatchString}`)   
                .Todo(`Szykujemy paczkę...`)
        }
    }
}
