import { Order } from "../Models/Order";
import { OrderStep } from "../Models/OrderStep";
import { CustomerStep } from "./CustomerStep";


export class Step3_PersonalPickup extends CustomerStep
{
    constructor(order: Order)
    {
        super(order, OrderStep.PersonalDelivery, "meeting.png", "Odbiór");

        if (order.Delivery.IsDelivered)
        {
            this.Label(`Odbiór ✅`);
        }
        else if (order.Delivery.IsDispatched)
        {
            this.Label(`Odbiór`);
        }
        else if (order.Delivery.PlanedDispatch.IsSet)
        {
            this.Label(`Spotkanie\n${order.Delivery.PlannedDispatchString}`);
            if (order.Delivery.DaysToPlanedDispatch >= 0)
                this.Todo(`już ${order.Delivery.DaysToDispatchString}`);
            else this.Todo(`powinno odbyć się ${order.Delivery.DaysToDispatchString}`);
        }
        else
        {
            this.Todo("Ustalanie daty spotkania...");
        }
    }
}
