import { DestroyingPatternContentSwitcher, Div, HashRef, Span } from "@tblabs/truffle";
import { inject, injectable } from "inversify";
import { OrderPage } from "./Pages/OrderPage";
import { NotExistingPage } from "./Pages/ErrorPage";


@injectable()
export class Main extends Div
{
    constructor(
        @inject(OrderPage) _orderPage: OrderPage)
    {
        super("Main");

        const content = new DestroyingPatternContentSwitcher(new HashRef()).Class("PagesSwitcher")
            .AddContent("order/:id", ({ id }) => _orderPage.Init(id))
            .AddDefaultContent(() => new NotExistingPage())

        this.Append(
            content,
        )
    }
}