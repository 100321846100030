import { Ref } from "@tblabs/truffle";


export class FakeDateTimeProvider
{
    public static Date = new Ref<Date>(new Date()).Storable('date');
    public static get Now()
    {
        return this.Date;
    }
}

export class DateTimeProvider
{
    public static get Now()
    {
        return new Date();
    }
}
