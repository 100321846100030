import { Div, Label, Span } from "@tblabs/truffle";
import { ChatMessage } from "./ChatMessage";
import moment from "moment";


export class MessageEntryBox extends Div
{
    constructor(message: ChatMessage)
    {
        super();
        this.Class("MessageBox").Class(message.Who);

        this.Append(
            new Label(message.Message).Class("message"),
            new Div("footer")
                .Append(
                    new Span(this.WhoToName(message.Who)).Italic(),
                    new Span(moment(message.When).format('D.MM.YYYY H:mm')).Class("time")
                )
        );
    }

    private WhoToName(who: string): string
    {
        switch (who)
        {
            case "Agent": return "😎 Agent wypożyczalni";
            case "Customer": return "🙂 Ty";
            case "System": return "😶 Wypożyczalnia (automat)";
            default: return "(nieznana encja)";
        }
    }
}
