import { Order } from "../Models/Order";
import { OrderStep } from "../Models/OrderStep";
import { CustomerStep } from "./CustomerStep";


export class Step5_PersonalReturn extends CustomerStep
{
    constructor(order: Order)
    {
        super(order, OrderStep.PackageReturn, "meeting.png", "Zwrot osobisty");

        if (order.Return.IsReturned)
        {
            this.Label(`Sprzęt zwrócony`);
        }
        else if (order.Return.IsReturnDeclared)
        {
            this.Label(`Zwrot`).Todo(`Pamiętaj o spotkaniu`);
        }

        else
        {
            this.Todo(`Umów się na spotkanie`);
        }
    }
}
