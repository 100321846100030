"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.EditableTextarea = void 0;
var ComponentBase_1 = require("../../Core/ComponentBase");
var Label_1 = require("./Label");
var MultilineInput_1 = require("./MultilineInput");
var EditableTextarea = /** @class */ (function (_super) {
    __extends(EditableTextarea, _super);
    function EditableTextarea(ref) {
        var _this = _super.call(this, "div") || this;
        _this.ref = ref;
        _this.valueBeforeEdit = "";
        _this.label = new Label_1.Label(ref)
            .CursorPointer()
            .Show()
            .OnClick(function () {
            _this.EditMode();
        });
        _this.textInput = new MultilineInput_1.MultilineInput(ref)
            .OnBlur(function () { return _this.EndOfEdit(); })
            .Hide();
        _this.Append(_this.label);
        _this.Append(_this.textInput);
        return _this;
    }
    EditableTextarea.prototype.EditMode = function () {
        var linesCount = this.ref.value.split("\n").length;
        this.textInput.Rows(linesCount);
        this.valueBeforeEdit = this.ref.value;
        var labelWidth = getComputedStyle(this.label.Element).width;
        var w = +labelWidth.replace("px", "");
        if (w < 100)
            w += 32;
        this.label.Hide();
        this.textInput
            .Show()
            .Width(w)
            .Padding(2, 6);
        // setTimeout(()=>        this.textInput.Focus(), 90)
        // .Focus()
    };
    Object.defineProperty(EditableTextarea.prototype, "IsEditMode", {
        get: function () {
            return this.textInput.IsVisible();
        },
        enumerable: false,
        configurable: true
    });
    EditableTextarea.prototype.ToggleEditMode = function () {
        if (this.IsEditMode)
            this.CloseEditMode();
        else
            this.EditMode();
        return this;
    };
    EditableTextarea.prototype.OpenEditMode = function () {
        this.EditMode();
        return this;
    };
    EditableTextarea.prototype.CloseEditMode = function () {
        this.EndOfEdit();
        return this;
    };
    EditableTextarea.prototype.LabelConfig = function (config) {
        config(this.label);
        return this;
    };
    EditableTextarea.prototype.TextInputConfig = function (config) {
        config(this.textInput);
        return this;
    };
    EditableTextarea.prototype.OnEditEnd = function (callback) {
        this.editEnd = callback;
        return this;
    };
    EditableTextarea.prototype.EndOfEdit = function () {
        var _a;
        if (!this.textInput.IsVisible())
            return; // To prevent double call
        this.textInput.Hide();
        this.label.Show();
        (_a = this.editEnd) === null || _a === void 0 ? void 0 : _a.call(this, this.valueBeforeEdit, this.ref.value);
    };
    EditableTextarea.prototype.DefaultText = function (text) {
        this.label.DefaultText(text);
        return this;
    };
    EditableTextarea.prototype.Placeholder = function (placeholder) {
        this.textInput.Placeholder(placeholder);
        return this;
    };
    EditableTextarea.prototype.Style = function (prop, val) {
        this.label.Style(prop, val);
        this.textInput.Style(prop, val);
        return this;
    };
    EditableTextarea.Name = "EditableTextarea";
    return EditableTextarea;
}(ComponentBase_1.ComponentBase));
exports.EditableTextarea = EditableTextarea;
