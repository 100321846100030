import { Order, State } from "../Models/Order";
import { OrderStep } from "../Models/OrderStep";
import { CustomerStep } from "./CustomerStep";


export class Step5_PackageReturn extends CustomerStep
{
    constructor(order: Order)
    {
        super(order, OrderStep.PackageReturn, "delivery.png", "Zwrot");

        if (order.Return.IsReturned && order.End.State.IsNot(State.Ok))
        {
            this.Label(`Paczka dotarła, ale...`)
                .Todo(`Coś jest nie tak`);
        }
        else if (order.Return.IsReturned)
        {
            this.Label(`Paczka dostarczona`)
                .Todo(`Trwa sprawdzanie zawartości`);
        }
        else if (order.Return.IsReturnDeclared)
        {
            this.Label(`Zadeklarowano odesłanie sprzętu`)
                .Todo(`Czekamy na paczkę od Ciebie`);
        }
        else this.Todo(`Odeślij sprzęt`);
    }
}
