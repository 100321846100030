
export class StorageResponse
{
    public IsSuccess: boolean = false;
    public ErrorMessage: string = "";
    public ErrorCode: number = (-1);
    public Result?: any = undefined;

    public static Success(result?: any): StorageResponse
    {
        const response = new StorageResponse();
        response.IsSuccess = true;
        response.Result = result;
        return response;
    }

    public static Fault(errorCode: number, errorMessage: string): StorageResponse
    {
        const response = new StorageResponse();
        response.IsSuccess = false;
        response.Result = undefined;
        response.ErrorCode = errorCode;
        response.ErrorMessage = errorMessage;
        return response;
    }
}
