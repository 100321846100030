import { Div, Image } from "@tblabs/truffle";
import { Order } from "../Models/Order";
import { OrderStep } from "../Models/OrderStep";

export class CustomerStep extends Div
{
    private icon = new Image(this.imgDir).Class("icon")

    private label = new Div().Text(this.text).Class("label")

    private toDoLabel = new Div().Hide().Class("todo")

    public get IsActive()
    {
        return this.order.Step.Is(this.step);
    }
    
    public get IsDone()
    {
        const currentStepIndex = Object.values(OrderStep).indexOf(this.order.Step.value);
        const thisStepIndex = Object.values(OrderStep).indexOf(this.step);

        return thisStepIndex < currentStepIndex;
    }

    constructor(private order: Order, private step: OrderStep, private imgDir, private text)
    {
        super();
        this.Class("CustomerStep")

        if (this.IsActive)
        {
            this.Active();
            this.label.Style(`text-decoration: underline`)
        }
        else 
        {
            this.icon.Style(`filter: grayscale(0.96); opacity: 0.5`)
            this.label.Color("#aaa")
        }

        this.Append(
            new Div("circle")
                .Append(this.icon),
            this.label,
            this.toDoLabel,
        )

        if (this.IsDone)
        {  
            this.icon.Style(`filter: grayscale(0.35); opacity: 0.9`)
            this.label.Color("#888").Text(this.label.GetText() + " ✅")
        }
    }

    public Icon(img)
    {
        this.icon.Src(img);
        return this;
    }

    public Label(txt)
    {
        this.label.Text(txt);
        return this;
    }

    public Todo(todo)
    {
        if (this.IsActive)
            this.toDoLabel.Show().Text(todo);
        return this;
    }

    public Active()
    {
        this.label.Bold();
        return this;
    }
}
