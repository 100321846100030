import 'reflect-metadata';
import { Div } from "@tblabs/truffle";
import { inject, injectable } from "inversify";
import { ICustomerRepo } from "../Services/Repo/ICustomerRepo";
import { Order } from '../Models/Order';
import { Center } from './Center';
import { MessageEntryBox } from './MessageEntryBox';
import { MessageBoxInput } from './MessageBoxInput';


@injectable()
export class CustomerMessagesBox extends Div
{
    constructor(@inject("ICustomerRepo") private _repo: ICustomerRepo)
    {
        super();
        this.Class("CustomerMessagesBox")
    }

    public Init(order: Order): this
    {
        order.Chat.Messages.OnChange(items =>
        {
            this.Clear().Append(
                !items.length && new Center("Brak wiadomości na czacie").Padding(32).Italic(),
                ...items.filter(x=>["Customer", "Agent", "System"].includes(x.Who)).map(x => new MessageEntryBox(x)),
                new MessageBoxInput(this._repo, order),
            )
           
        }, true)

        return this;
    }
}
