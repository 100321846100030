import { Div } from "@tblabs/truffle";
import { Step6_DepositReturn } from "../Steps/Step6_DepositReturn";
import { Step5_Return } from "../Steps/Step5_Return";
import { Step4_InUsage } from "../Steps/Step4_InUsage";
import { Step3_PackageDelivery } from "../Steps/Step3_PackageDelivery";
import { Step3_PersonalPickup } from "../Steps/Step3_PersonalPickup";
import { Step2_Deposit } from "../Steps/Step2_Deposit";
import { Step1_OrderStart } from "../Steps/Step1_OrderStart";
import { Arrow } from "./Arrow";
import { Step0_Rejected } from "../Steps/Step0_Rejected";
import { Order, OrderType } from "../Models/Order";
import { OrderStep } from "../Models/OrderStep";
import { PickupMethod } from "../Models/PickupMethod";
import { Step5_PackageReturn } from "../Steps/Step5_PackageReturn";
import { Step5_PersonalReturn } from "../Steps/Step5_PersonalReturn";
import { ReturnMethod } from "../Models/ReturnMethod";


export class StatusBox extends Div
{
    constructor(private order: Order)
    {
        super();
        this.Class("StatusBox").TextAlignCenter()
        this.Append("Loading status...");
    }

    protected async OnAppend(): Promise<void>
    {
        this.Clear()

        if (this.order.Type != OrderType.Rental)
        {
            return;
        }

        const start = new Step1_OrderStart(this.order);
        const deposit = new Step2_Deposit(this.order)
            .VisibleWhen(this.order.Delivery.Method, v => v == PickupMethod.Package)
        const delivery = new Step3_PackageDelivery(this.order)
            .VisibleWhen(this.order.Delivery.Method, v => v == PickupMethod.Package)
        const personalPickup = new Step3_PersonalPickup(this.order)
            .VisibleWhen(this.order.Delivery.Method, v => v == PickupMethod.Personal)
        const action = new Step4_InUsage(this.order)
        const packageReturn = new Step5_PackageReturn(this.order)
            .VisibleWhen(this.order.Return.Method, v => v == ReturnMethod.Package)
        const personalReturn = new Step5_PersonalReturn(this.order)
            .VisibleWhen(this.order.Return.Method, v => v == ReturnMethod.Personal)
        const depositReturn = new Step6_DepositReturn(this.order)

        this.Append(
            start,
            new Arrow(),
        )

        if (this.order.Step.Is(OrderStep.Rejected))
        {
            this.Append(
                new Step0_Rejected(this.order),
            )
        }
        else
            this.Append(
                deposit,
                new Arrow()
                    .VisibleWhen(this.order.Delivery.Method, v => v == PickupMethod.Package),
                delivery,
                personalPickup,
                new Arrow(),
                action,
                new Arrow(),
                packageReturn,
                personalReturn,
                new Arrow(),
                depositReturn,
            )

        let active;
        if (start.IsActive) active = start.element;
        if (deposit.IsActive) active = deposit.element;
        if (delivery.IsActive) active = delivery.element;
        if (personalPickup.IsActive) active = personalPickup.element;
        if (action.IsActive) active = action.element;
        if (packageReturn.IsActive) active = packageReturn.element;
        if (personalReturn.IsActive) active = personalReturn.element;
        if (depositReturn.IsActive) active = depositReturn.element;
        if (this.order.Step.Is(OrderStep.End))
            active = depositReturn.element;

        this.element.scrollLeft = active?.offsetLeft - (this.element.offsetWidth / 2) + active?.offsetWidth / 2;
    }
}
