"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.LabeledTextInput2 = exports.LabeledTextInput = void 0;
var TextInput_1 = require("./TextInput");
var TextLabel_1 = require("./TextLabel");
var ComponentBase_1 = require("../../Core/ComponentBase");
var Ref_1 = require("../../Core/Ref");
var Span_1 = require("../Containers/Span");
var Div_1 = require("../Containers/Div");
var LabeledTextInput = /** @class */ (function (_super) {
    __extends(LabeledTextInput, _super);
    function LabeledTextInput(label, variable) {
        if (variable === void 0) { variable = new Ref_1.Ref(""); }
        var _this = _super.call(this, "div") || this;
        _this.Append(new TextLabel_1.TextLabel(label)
            .Class("tf-form-label"));
        _this.Append(new TextInput_1.TextInput(variable)
            .Placeholder(label)
            .Class("tf-form-input"));
        return _this;
    }
    return LabeledTextInput;
}(ComponentBase_1.ComponentBase));
exports.LabeledTextInput = LabeledTextInput;
var LabeledTextInput2 = /** @class */ (function (_super) {
    __extends(LabeledTextInput2, _super);
    function LabeledTextInput2(label, variable) {
        if (variable === void 0) { variable = new Ref_1.Ref(""); }
        var _this = _super.call(this) || this;
        _this.Append(new Span_1.Span(label).Width(320).DisplayInlineBlock(), new TextInput_1.TextInput(variable).Placeholder(label).Width(200));
        return _this;
    }
    return LabeledTextInput2;
}(Div_1.Div));
exports.LabeledTextInput2 = LabeledTextInput2;
