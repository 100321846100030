import { Order } from "../Models/Order";
import { OrderStep } from "../Models/OrderStep";
import { CustomerStep } from "./CustomerStep";

export class Step1_OrderStart extends CustomerStep
{
    constructor(order: Order)
    {
        super(order, OrderStep.New, "shop.png", "Złożenie");

        if (!order.IsConfirmed)
        {
            this.Todo("Czekaj na potwierdzenie");
        }
    }
}
