import { Div, Link } from "@tblabs/truffle";
import { inject, injectable } from "inversify";
import { ICustomerRepo } from "../Services/Repo/ICustomerRepo";
import { StatusBox } from "../Components/StatusBox";
import { CustomerMessagesBox } from "../Components/MessagesBox";
import { Page } from "./Page";
import moment from "moment";
import { TitleBar } from "../Components/TitleBar";
import { Order } from "../Models/Order";

@injectable()
export class OrderPage extends Page
{
    private orderId!: string;

    constructor(
        @inject("ICustomerRepo") private _repo: ICustomerRepo,
        @inject(CustomerMessagesBox) private _messagesBox: CustomerMessagesBox)
    {
        super("OrderPage");

        this.top.Append(`Ładowanie...`);
    }

    public Init(orderId: string)
    {
        this.orderId = orderId;

        return this;
    }

    protected async OnAppend(): Promise<void>
    {
        const order = await this._repo.Read(this.orderId);

        if (!order)
        {
            this.PrintErrorPage();

            return;
        }

        await this.RegisterPageRefresh(order);

        this.top
            .Clear()
            .Append(
                new TitleBar(order),
                new StatusBox(order),
            )
            .PositionRelative()
            .Append(new Div("curtain"))

        const messagesBox = this._messagesBox.Init(order)
        
        this.middle
            .Append(messagesBox)
            .ScrollToBottom()
    }

    private PrintErrorPage()
    {
        this.Clear().middle.Append(
            new Div()
                .Background("lightblue").Margin(0).Padding(0)
                .WidthPercent(100).HeightPercent(100).PaddingTop(32)
                .FontSize(18).TextAlignCenter()
                .Append(
                    "\n\n😥 Niestety zamówienie, którego szukasz już nie istnieje, zostało zamknięte lub jego numer jest niepoprawny.\nSkontaktuj się z kimś z naszej obsługi pod adresem ",
                    new Link("www.specteam.pl", "https://specteam.pl/Kontakt")
                )
        );
    }

    private async RegisterPageRefresh(order: Order)
    {
        await this._repo.AddMessage(order.Id, "Info", `Klient odświeżył strone ${moment().format("DD-MM-YYYY HH:mm")}`);
    }
}
