"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModalWindow = void 0;
var _1 = require("../Core/$");
var HandlersCollection_1 = require("../Core/Tools/HandlersCollection");
var Div_1 = require("./Containers/Div");
var Span_1 = require("./Containers/Span");
var Link_1 = require("./Elements/Link");
var Shutter_1 = require("./Shutter");
var ModalWindow = /** @class */ (function (_super) {
    __extends(ModalWindow, _super);
    function ModalWindow(title, blurBackground, lockScroll, closeOnClickAway) {
        if (blurBackground === void 0) { blurBackground = true; }
        if (lockScroll === void 0) { lockScroll = false; }
        if (closeOnClickAway === void 0) { closeOnClickAway = false; }
        var _this = _super.call(this) || this;
        _this.title = title;
        _this.blurBackground = blurBackground;
        _this.lockScroll = lockScroll;
        _this.closeOnClickAway = closeOnClickAway;
        _this.shutter = new Shutter_1.Shutter();
        _this.headerTitle = new Span_1.Span().Text(_this.title).Class("tf-modal-window-header");
        _this.header = new Div_1.Div()
            .Append(_this.headerTitle);
        _this.onBeforeCloseOrHideWindow = new HandlersCollection_1.HandlersCollection();
        _this.onCloseOrHideWindow = new HandlersCollection_1.HandlersCollection();
        _this.CloseButtonAction = function () { return _this.CloseWindow(); };
        _this.CloseButton = new Link_1.Link("x").NoDecorationOnHover().Class("tf-modal-window-close")
            .OnClick(function () { return _this.CloseButtonAction(); });
        _this.content = new Div_1.Div().Class("tf-modal-window-content");
        _this.footer = new Div_1.Div().Class("tf-modal-window-footer");
        _this.wasEverOpen = false;
        _this.Class(ModalWindow.DefaultCssClasses);
        return _this;
    }
    Object.defineProperty(ModalWindow.prototype, "Shutter", {
        get: function () {
            return this.shutter;
        },
        enumerable: false,
        configurable: true
    });
    ModalWindow.prototype.NoCloseButton = function () {
        this.CloseButton = null;
        return this;
    };
    ModalWindow.prototype.SetCloseButtonAction = function (handler) {
        this.CloseButtonAction = handler;
        return this;
    };
    ModalWindow.prototype.FullScreen = function () {
        return this.WidthPercent(100).HeightPercent(100);
    };
    ModalWindow.prototype.HideOnClose = function () {
        var _this = this;
        this.CloseButtonAction = function () { return _this.HideWindow(); };
        return this;
    };
    ModalWindow.prototype.OpenWindow = function () {
        var _this = this;
        var _a, _b;
        if (this.CloseButton)
            this.Append(this.CloseButton);
        this.Append(this.header, this.content, this.footer);
        if (this.blurBackground) {
            (_a = (0, _1.$)(ModalWindow.Hook)) === null || _a === void 0 ? void 0 : _a.Append(this.shutter.Element);
        }
        (_b = (0, _1.$)(ModalWindow.Hook)) === null || _b === void 0 ? void 0 : _b.Append(this.Element);
        if (this.lockScroll)
            this.LockPageScroll();
        if (this.closeOnClickAway) {
            this.Shutter.OnClick(function () { return _this.CloseButtonAction(); });
        }
        return this;
    };
    ModalWindow.prototype.Title = function (title) {
        this.headerTitle.Text(title);
        return this;
    };
    ModalWindow.prototype.CloseWindow = function () {
        var _a, _b;
        if (this.onBeforeCloseOrHideWindow.AreAny) {
            var allowClose = this.onBeforeCloseOrHideWindow.CallAndGatherReturnValue();
            if (allowClose.some(function (x) { return x == false; })) {
                return this;
            }
        }
        this.Clear();
        try {
            (_a = (0, _1.$)(ModalWindow.Hook)) === null || _a === void 0 ? void 0 : _a.removeChild(this.Element);
            if (this.blurBackground) {
                (_b = (0, _1.$)(ModalWindow.Hook)) === null || _b === void 0 ? void 0 : _b.removeChild(this.shutter.Element);
            }
        }
        catch (error) {
            throw new Error("[truffle] You are trying to remove element (that window) that is not a child of ".concat(ModalWindow.Hook, ". Make sure Root.Hook and ModalWindow.Root are the same. Remember to not attach ModalWindow to any other element with .Append()"));
        }
        this.onCloseOrHideWindow.Call();
        if (this.lockScroll)
            this.UnlockPageScroll();
        return this;
    };
    ModalWindow.prototype.ShowWindow = function () {
        var _a;
        if (this.wasEverOpen == false) {
            this.OpenWindow();
            this.wasEverOpen = true;
        }
        (_a = (0, _1.$)(ModalWindow.Hook)) === null || _a === void 0 ? void 0 : _a.Append(this.shutter.Element);
        this.Show().ZIndex(100);
        return this;
    };
    ModalWindow.prototype.HideWindow = function () {
        var _a;
        if (this.blurBackground) {
            (_a = (0, _1.$)(ModalWindow.Hook)) === null || _a === void 0 ? void 0 : _a.removeChild(this.shutter.Element);
        }
        this.Hide();
        this.onCloseOrHideWindow.Call();
        if (this.lockScroll)
            this.UnlockPageScroll();
        return this;
    };
    ModalWindow.prototype.Header = function (cnt) {
        cnt(this.header);
        return this;
    };
    ModalWindow.prototype.Content = function (cnt) {
        cnt(this.content);
        return this;
    };
    ModalWindow.prototype.AddContent = function () {
        var _a;
        var elements = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            elements[_i] = arguments[_i];
        }
        (_a = this.content).Append.apply(_a, elements);
        return this;
    };
    ModalWindow.prototype.PrependContent = function () {
        var _a;
        var elements = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            elements[_i] = arguments[_i];
        }
        (_a = this.content).Prepend.apply(_a, elements);
        return this;
    };
    ModalWindow.prototype.TextContent = function (text) {
        this.content.Text(text);
        return this;
    };
    ModalWindow.prototype.Footer = function (callback) {
        callback(this.footer);
        return this;
    };
    ModalWindow.prototype.AddToFooter = function () {
        var _a;
        var elements = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            elements[_i] = arguments[_i];
        }
        (_a = this.footer).Append.apply(_a, elements);
        return this;
    };
    ModalWindow.prototype.OnCloseWindow = function (handler) {
        this.onCloseOrHideWindow.Add(handler);
        return this;
    };
    ModalWindow.prototype.OnBeforeCloseWindow = function (handler) {
        this.onBeforeCloseOrHideWindow.Add(handler);
        return this;
    };
    ModalWindow.prototype.LockPageScroll = function () {
        document.body.classList.add("tf-stop-scrolling");
    };
    ModalWindow.prototype.UnlockPageScroll = function () {
        document.body.classList.remove("tf-stop-scrolling");
    };
    ModalWindow.Name = "ModalWindow";
    ModalWindow.DefaultCssClasses = "";
    ModalWindow.Hook = "body";
    return ModalWindow;
}(Div_1.Div));
exports.ModalWindow = ModalWindow;
