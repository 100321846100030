import { Order } from "../Models/Order";
import { OrderStep } from "../Models/OrderStep";
import { CustomerStep } from "./CustomerStep";


export class Step4_InUsage extends CustomerStep
{
    constructor(order: Order)
    {
        super(order, OrderStep.InUse, "rocket.png", `Użytkowanie`);

        if (this.IsActive)
        {
            this.Label(`Użytkowanie do ${order.Timeline.ToString}`);
        }

        this.Todo(order.Timeline.DaysLeftString);
    }
}
