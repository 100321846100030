import { Div, Span } from "@tblabs/truffle";
import { Order, OrderType } from "../Models/Order";


export class TitleBar extends Div
{
    constructor(order: Order)
    {
        super("title");

        this.Append(
            new Div("order-id").Text(`${order.Type == OrderType.Talk ? "Zgłoszenie" : "Zamówienie"} #${order.Id.slice(-6)}`),
            order.TypeAsString, " ",
            new Span(order.Basket.AsString).Bold().Color("#ff7b0d"),
            order.Type == OrderType.Rental && `\nw dniach `,
            order.Type == OrderType.Rental && new Span(`${order.Timeline.FromString} - ${order.Timeline.ToString}`).Bold()
        );
    }
}
