import { Div, Link } from "@tblabs/truffle";


export class NotExistingPage extends Div
{
    constructor()
    {
        super();
        this.Background("lightblue").Margin(0).Padding(0).WidthPercent(100).HeightPercent(100).PaddingTop(32).FontSize(18).TextAlignCenter();

        this.Append(
            "😥 Niestety adres wpisany w pasku przeglądarki jest niepoprawny.\nUpewnij się że jest poprawny lub skontaktuj się z kimś z naszej obsługi pod adresem ",
            new Link("www.specteam.pl", "https://specteam.pl/Kontakt")
        );
    }
}
