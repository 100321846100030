import { Ref } from "@tblabs/truffle";
import { injectable } from "inversify";



@injectable()
export class CustomerRouter
{
    private history: string[] = [];
    public Hash = new Ref<string>("order/123").Storable("customer-url")
        .OnChange(v => this.history.push(v));

    public GoBack()
    {
        this.Hash.value = this.history.pop() || "order";
    }
}
