import { Order } from "../Models/Order";
import { OrderStep } from "../Models/OrderStep";
import { CustomerStep } from "./CustomerStep";

export class Step2_Deposit extends CustomerStep
{
    constructor(order: Order)
    {
        super(order, OrderStep.Deposit, "cash.png", "Wpłata kaucji");

        this.Todo("Wpłać kaucję do " + order.Deposit.PaymentDeadlineString);
    }
}
