"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JsonViewerConfig = void 0;
var JsonViewerConfig = /** @class */ (function () {
    function JsonViewerConfig() {
        this.foldAll = true;
        this.foldSymbol = "➕";
        this.unfoldSymbol = "➖";
        this.keyPrinter = function (key) { return "◾ " + key + ":"; };
        this.keyStyle = "color: #222";
        this.stringStyle = "color: green";
        this.numberStyle = "color: blue";
        this.boolStyle = "color: violet";
        this.nullStyle = "color: pink;";
        this.typeInfoStyle = "color: #aaa";
    }
    return JsonViewerConfig;
}());
exports.JsonViewerConfig = JsonViewerConfig;
