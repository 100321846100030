import { inject, injectable } from "inversify";
import { ICustomerRepo } from "./ICustomerRepo";
import { ChatMessage } from '../../Components/ChatMessage';
import { RefSerializer } from "@tblabs/truffle";
import { OnlineStorage } from "../Storage/OnlineStorage";
import { nameof } from "../Storage/nameof";
import { AddDeepToArrayCommand } from "../Storage/Messages/AddToArrayCommand";
import { ReadQuery } from "../Storage/Messages/ReadQuery";
import { Order, OrderChat } from "../../Models/Order";


@injectable()
export class CustomerOrderRepo implements ICustomerRepo
{
    constructor(@inject(OnlineStorage) private _onlineStorage: OnlineStorage) { }

    public async AddMessage(orderId: string, type: "Customer"|"Info", msg: string): Promise<boolean>
    {
        const field1 = nameof<Order>("Chat");
        const field2 = nameof<OrderChat>("Messages");
        const entry = RefSerializer.Flatenize(new ChatMessage(type, msg));

        const commandResult = await this._onlineStorage.SendMessage(
            new AddDeepToArrayCommand(orderId, [field1, field2], entry));

        return commandResult.IsSuccess;
    }

    public async Read(orderId: string): Promise<Order | null>
    {
        const queryResult = await this._onlineStorage.SendMessage(new ReadQuery(orderId));

        if (queryResult.IsSuccess)
        {
            const rawOrder = queryResult.Result;

            return new Order().FromPlainObject(rawOrder);
        }

        console.error(`Could not fetch order "${orderId}": ${queryResult.ErrorMessage}`);
        return null;
    }
}
