import { Ref } from "@tblabs/truffle";
import { DateTimeProvider } from "../Services/DateTimeProvider";


export class ChatMessage
{
    public Message = new Ref<string>("");

    constructor(
        public Who: "Customer" | "Info",
        message: string,
        public When = DateTimeProvider.Now)
    {
        this.Message.value = message;
    }
}
