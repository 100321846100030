import { Message } from './Message';


export class AddDeepToArrayCommand extends Message
{
    constructor(
        Id: string,
        Fields: string[],
        Entry: any)
    {
        super("AddDeepToArray", { Id, Fields, Entry });
    }
}
