"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.RefBool = exports.RefString = exports.RefNumber = exports.RefDate = exports.Ref = void 0;
require("./Extensions/ArrayExtensions");
var RefBase_1 = require("./RefBase");
var Ref = /** @class */ (function (_super) {
    __extends(Ref, _super);
    function Ref(_value, allowMasterEmit) {
        if (allowMasterEmit === void 0) { allowMasterEmit = false; }
        var _this = _super.call(this) || this;
        _this._value = _value;
        _this.allowMasterEmit = allowMasterEmit;
        _this.initialValue = _value;
        return _this;
    }
    Ref.prototype.Storable = function (key, storage) {
        if (storage === void 0) { storage = "local"; }
        var stor;
        switch (storage) {
            case "local":
                stor = window.localStorage;
                break;
            case "session":
                stor = window.sessionStorage;
                break;
        }
        var value = stor.getItem(key);
        if (this._value instanceof Date) {
            this.Set((new Date(value) || new Date(this._value) || ""));
        }
        else if (typeof this._value == "string") {
            this.Set((value || this._value || ""));
        }
        else if (typeof this._value == "number") {
            this.Set((+value || 0));
        }
        else if (typeof this._value == "boolean") {
            switch (value) {
                case "":
                case "false":
                    this.Set(false);
                    break;
                default:
                case "true":
                    this.Set(true);
                    break;
            }
        }
        this.OnChange(function (v) { return stor.setItem(key, v.toString()); });
        return this;
    };
    Ref.prototype.Compare = function (ref, valueOrRef, op) {
        if (valueOrRef instanceof Ref) {
            return op(ref.value, valueOrRef.value);
        }
        return op(ref.value, valueOrRef);
    };
    Ref.prototype.Is = function (toCompare) {
        return this.Compare(this, toCompare, function (a, b) { return a == b; });
    };
    Ref.prototype.IsNot = function (toCompare) {
        return this.Compare(this, toCompare, function (a, b) { return a != b; });
    };
    Object.defineProperty(Ref.prototype, "IsSet", {
        get: function () {
            if (this._value instanceof Date) {
                return +this._value !== +this.ZeroValue;
            }
            return this._value !== this.ZeroValue;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Ref.prototype, "IsNotSet", {
        get: function () {
            return !this.IsSet;
        },
        enumerable: false,
        configurable: true
    });
    Ref.prototype.IsInitial = function () {
        return this.value == this.initialValue;
    };
    Ref.prototype.IsNotInitial = function () {
        return this.value != this.initialValue;
    };
    Object.defineProperty(Ref.prototype, "HasChanged", {
        get: function () {
            return this._value !== this.initialValue;
        },
        enumerable: false,
        configurable: true
    });
    Ref.prototype.IsEqual = function (toCompare) {
        return this.Compare(this, toCompare, function (a, b) { return a === b; });
    };
    Ref.prototype.IsIn = function (values) {
        return values.includes(this.value);
    };
    Ref.prototype.IsNotEqual = function (value) {
        return this.value !== value;
    };
    // public static masterEmitter = new EventEmitter(); // PROBLEMATIC DURING PUBLISH
    Ref.prototype.Set = function (val, excludeControlForRefresh) {
        if (this.value === val)
            return false;
        // this.prevVal = this._value;
        this._value = val;
        this.RefreshSubscribers(excludeControlForRefresh);
        this.emitter.CallHandlers(this._value);
        // if (this.allowMasterEmit)
        // Ref.masterEmitter.emit('set', this, this.prevVal, val)
        return true;
    };
    Ref.prototype.SetTo = function (valueOrRef) {
        if (valueOrRef instanceof Ref) {
            this.value = valueOrRef.value;
        }
        else {
            this.value = valueOrRef;
        }
        return this;
    };
    Ref.prototype.ForceOnChange = function () {
        this.RefreshSubscribers();
        this.emitter.CallHandlers(this._value);
        return this;
    };
    Object.defineProperty(Ref.prototype, "value", {
        // public SetValue(val: T, excludeControlForRefresh: any): boolean
        // {
        //     return this.Set(val, excludeControlForRefresh);
        // }
        get: function () {
            return this._value;
        },
        set: function (val) {
            this.Set(val);
        },
        enumerable: false,
        configurable: true
    });
    Ref.prototype.Reset = function () {
        this.value = this.initialValue;
        return this;
    };
    Ref.prototype.OverrideInitialValue = function (val) {
        this.initialValue = val;
        return this;
    };
    Ref.prototype.Initialize = function (val) {
        this.initialValue = val;
        this._value = val;
        return this;
    };
    Object.defineProperty(Ref.prototype, "ZeroValue", {
        get: function () {
            if (this._value instanceof Date) {
                return new Date(0);
            }
            else if (typeof this._value == "string") {
                return "";
            }
            else if (typeof this._value == "number") {
                return 0; // TODO: 0? not infinity or something?
            }
            else if (typeof this._value == "boolean") {
                return false;
            }
            throw new Error("No zero value for given type");
        },
        enumerable: false,
        configurable: true
    });
    Ref.prototype.Clear = function () {
        if (typeof this._value == "string") {
            this.Set("");
        }
        else if (typeof this._value == "number") {
            this.Set(0);
        }
        else if (typeof this._value == "boolean") {
            this.Set(false);
        }
        else if (this._value instanceof Date) {
            this.Set(new Date(0));
        }
        return this;
    };
    Ref.prototype.ToString = function () {
        var _a, _b, _c;
        // console.log('ttt');
        // return `${this._value?.toString()} [${this.watchers?.map(w=>w.Id)?.join(", ")}]`;
        // if (typeof this._value === "string")
        //     return `Ref<${typeof this._value}>#${this.Id}${this.Name ? ":" + this.Name : ""} = "${this._value}" [${this.watchers?.map(w => w.Id)?.join(", ")}]`;
        // else
        // if (typeof this._value === "Date")
        // return `Ref<${typeof this._value}> = "${this._value?.toString()}" [${this.watchers?.map(w => w.Id)?.join(", ")}]`;
        // else
        // if (typeof this._value === "boolean")
        //     return `Ref<${typeof this._value}> = ${this._value?.toString() ? "true" : "false"} [${this.watchers?.map(w => w.Id)?.join(", ")}]`;
        // else
        return "Ref<".concat(typeof this._value, ">#").concat(this.Id).concat(this.Name ? ":" + this.Name : "", " = ").concat((_a = this._value) === null || _a === void 0 ? void 0 : _a.toString(), " [watchers: ").concat(((_c = (_b = this.watchers) === null || _b === void 0 ? void 0 : _b.map(function (w) { return w.Id; })) === null || _c === void 0 ? void 0 : _c.join(", ")) || "none", "][").concat(this.emitter.Subs, " subs]");
    };
    Ref.prototype.Combine = function (anotherRef, operation) {
        var _this = this;
        var result = new Ref(operation(this.value, anotherRef.value));
        this.OnChange(function (v) { return result.value = operation(_this.value, anotherRef.value); });
        anotherRef.OnChange(function (v) { return result.value = operation(_this.value, anotherRef.value); });
        return result;
    };
    Ref.prototype.CombineValue = function (anotherValue, operation) {
        var result = new Ref(operation(this.value, anotherValue));
        this.OnChange(function (v) { return result.value = operation(v, anotherValue); }, true);
        return result;
    };
    Ref.prototype.Mix = function (x, operation) {
        if (x instanceof Ref) {
            return this.Combine(x, operation);
        }
        else {
            return this.CombineValue(x, operation);
        }
    };
    return Ref;
}(RefBase_1.RefBase));
exports.Ref = Ref;
var RefDate = /** @class */ (function (_super) {
    __extends(RefDate, _super);
    function RefDate(initialDate) {
        if (initialDate === void 0) { initialDate = new Date(0); }
        var _this = this;
        if (initialDate instanceof Date) {
            _this = _super.call(this, initialDate, false) || this;
        }
        else if (typeof initialDate === "number") {
            _this = _super.call(this, new Date(initialDate), false) || this;
        }
        else if (typeof initialDate === "string") {
            _this = _super.call(this, new Date(initialDate), false) || this;
        }
        return _this;
    }
    return RefDate;
}(Ref));
exports.RefDate = RefDate;
var RefNumber = /** @class */ (function (_super) {
    __extends(RefNumber, _super);
    function RefNumber(initialDate) {
        if (initialDate === void 0) { initialDate = 0; }
        return _super.call(this, initialDate, false) || this;
    }
    RefNumber.prototype.IsBigger = function (toCompare) {
        return this.Compare(this, toCompare, function (a, b) { return a > b; });
    };
    RefNumber.prototype.IsBiggerOrEqual = function (toCompare) {
        return this.Compare(this, toCompare, function (a, b) { return a >= b; });
    };
    RefNumber.prototype.IsSmaller = function (toCompare) {
        return this.Compare(this, toCompare, function (a, b) { return a < b; });
    };
    RefNumber.prototype.IsSmallerrOrEqual = function (toCompare) {
        return this.Compare(this, toCompare, function (a, b) { return a <= b; });
    };
    RefNumber.prototype.Plus = function (x) {
        return this.Mix(x, function (a, b) { return a + b; });
    };
    RefNumber.prototype.Minus = function (x) {
        return this.Mix(x, function (a, b) { return a - b; });
    };
    RefNumber.prototype.Multiply = function (x) {
        return this.Mix(x, function (a, b) { return a * b; });
    };
    RefNumber.prototype.Divide = function (x) {
        return this.Mix(x, function (a, b) { return a / b; });
    };
    return RefNumber;
}(Ref));
exports.RefNumber = RefNumber;
var RefString = /** @class */ (function (_super) {
    __extends(RefString, _super);
    function RefString(initialDate) {
        if (initialDate === void 0) { initialDate = ""; }
        return _super.call(this, initialDate, false) || this;
    }
    RefString.prototype.Includes = function (toCompare) {
        // return value.includes(this.value as string);
        return this.Compare(this, toCompare, function (a, b) { return a.includes(b); });
    };
    return RefString;
}(Ref));
exports.RefString = RefString;
var RefBool = /** @class */ (function (_super) {
    __extends(RefBool, _super);
    function RefBool(initialDate) {
        if (initialDate === void 0) { initialDate = false; }
        return _super.call(this, initialDate, false) || this;
    }
    RefBool.prototype.CombineRefBool = function (anotherRef, operation) {
        var _this = this;
        var result = new RefBool(operation(this.value, anotherRef.value));
        this.OnChange(function (v) { return result.value = operation(_this.value, anotherRef.value); });
        anotherRef.OnChange(function (v) { return result.value = operation(_this.value, anotherRef.value); });
        return result;
    };
    RefBool.prototype.CombineRefBoolValue = function (anotherValue, operation) {
        var result = new RefBool(operation(this.value, anotherValue));
        this.OnChange(function (v) { return result.value = operation(v, anotherValue); }, true);
        return result;
    };
    RefBool.prototype.MixRefBool = function (x, operation) {
        if (x instanceof Ref) {
            return this.CombineRefBool(x, operation);
        }
        else {
            return this.CombineRefBoolValue(x, operation);
        }
    };
    RefBool.prototype.And = function (anotherRef) {
        return this.MixRefBool(anotherRef, function (a, b) { return a && b; });
    };
    RefBool.prototype.Or = function (anotherRef) {
        return this.MixRefBool(anotherRef, function (a, b) { return a || b; });
    };
    RefBool.prototype.Toggle = function () {
        this.value = !this.value;
        return this;
    };
    RefBool.prototype.True = function () {
        this.value = true;
        return this;
    };
    RefBool.prototype.IsTrue = function () {
        return this.value == true;
    };
    RefBool.prototype.IsFalse = function () {
        return this.value == false;
    };
    RefBool.prototype.False = function () {
        this.value = false;
        return this;
    };
    return RefBool;
}(Ref));
exports.RefBool = RefBool;
