import { Order } from "../Models/Order";
import { OrderStep } from "../Models/OrderStep";
import { CustomerStep } from "./CustomerStep";


export class Step6_DepositReturn extends CustomerStep
{
    constructor(order: Order)
    {
        super(order, OrderStep.DepositReturn, "cash.png", "Zwrot kaucji");

        if (order.Deposit.IsReturned)
        {
            this.Label(`Kaucja zwrócona`)
        }
        else 
        {
            this.Todo(`Oczekuj na zwrot`);
        }
    }
}
