"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.JsonViewer = void 0;
var TwoFloatingColumns_1 = require("../../Containers/TwoFloatingColumns");
var Link_1 = require("../Link");
var Div_1 = require("../../Containers/Div");
var JsonViewerConfig_1 = require("./JsonViewerConfig");
var Span_1 = require("../../Containers/Span");
var JsonViewer = /** @class */ (function (_super) {
    __extends(JsonViewer, _super);
    function JsonViewer(obj, key, config) {
        if (key === void 0) { key = ""; }
        if (config === void 0) { config = new JsonViewerConfig_1.JsonViewerConfig(); }
        var _this = _super.call(this) || this;
        _this.config = config;
        var tk = new TwoFloatingColumns_1.TwoFloatingColumns();
        var objType = typeof obj;
        var isArray = Array.isArray(obj);
        if (key) {
            tk.LeftColumn.Append(new Span_1.Span(_this.config.keyPrinter(key)).FloatLeft().MarginRight(6).Style(_this.config.keyStyle));
        }
        if (obj === null) {
            tk.RightColumn.Append(new Span_1.Span("null").Margin(8).MarginLeft(0).Style(_this.config.nullStyle));
        }
        else if (objType === "string") {
            tk.RightColumn.Append(new Span_1.Span("\"".concat(obj, "\"")).Margin(8).MarginLeft(0).MarginRight(4).Style(_this.config.stringStyle));
            tk.RightColumn.Append(new Span_1.Span("(".concat(obj.length, " chars)")).Style(_this.config.typeInfoStyle));
        }
        else if (objType === "number") {
            tk.RightColumn.Append(new Span_1.Span(obj.toString()).Margin(8).MarginLeft(0).Style(_this.config.numberStyle));
        }
        else if (objType === "boolean") {
            tk.RightColumn.Append(new Span_1.Span(obj ? "true" : "false").Margin(8).MarginLeft(0).Style(_this.config.boolStyle));
        }
        else if (isArray) {
            if (obj.length === 0) {
                tk.RightColumn.Append(new Span_1.Span("(empty array)").Color("#aaa"));
            }
            else {
                var folder_1 = new Link_1.Link(_this.config.foldSymbol).NoDecorationOnHover().FloatLeft().MarginLeft(4).CursorPointer();
                tk.LeftColumn.Append(new Span_1.Span("(array of ".concat(obj.length, " ").concat(_this.GetType(obj[0])).concat((obj.length > 1 ? "s" : ""), ")")).FloatLeft().Style(_this.config.typeInfoStyle));
                tk.LeftColumn.Append(folder_1)
                    .OnClick(function () {
                    folder_1.Text(tk.RightColumn.IsVisible() ? _this.config.foldSymbol : _this.config.unfoldSymbol);
                    tk.RightColumn.Toggle();
                });
                if (_this.config.foldAll)
                    tk.RightColumn.Hide();
                tk.RightColumn.MarginTop(23).MarginLeft(-16);
                obj.forEach(function (e, i) { return tk.RightColumn.Append(new JsonViewer(e, i.toString(), _this.config)); });
            }
        }
        else if (objType === "object") {
            var keys = Object.keys(obj);
            if (keys.length === 0) {
                tk.RightColumn.Append(new Span_1.Span("(empty object)").Style(_this.config.typeInfoStyle));
            }
            else {
                if (key) {
                    var folder_2 = new Link_1.Link(_this.config.foldSymbol).NoDecorationOnHover().FloatLeft().MarginLeft(4).CursorPointer();
                    tk.LeftColumn.Append(new Span_1.Span("(".concat(keys.length, " field").concat((keys.length > 1 ? "s" : ""), " object)")).FloatLeft().Style(_this.config.typeInfoStyle));
                    tk.LeftColumn.Append(folder_2)
                        .OnClick(function () {
                        folder_2.Text(tk.RightColumn.IsVisible() ? _this.config.foldSymbol : _this.config.unfoldSymbol);
                        tk.RightColumn.Toggle();
                    });
                    tk.RightColumn.MarginTop(23).MarginLeft(-16);
                    if (_this.config.foldAll)
                        tk.RightColumn.Hide();
                }
                keys.forEach(function (k) {
                    var v = obj[k];
                    tk.RightColumn.Append(new JsonViewer(v, k, _this.config));
                });
            }
        }
        _this.Append(tk);
        _this.Append(new Div_1.Div().ClearBoth());
        return _this;
    }
    JsonViewer.prototype.GetType = function (obj) {
        var objType = typeof obj;
        var isArray = Array.isArray(obj);
        if (isArray && obj.length > 0)
            return "array of ".concat(this.GetType(obj[0]), "s");
        else if (isArray)
            return "array";
        else
            return objType;
    };
    JsonViewer.Name = "JsonViewer";
    return JsonViewer;
}(Div_1.Div));
exports.JsonViewer = JsonViewer;
