"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.CollectionPaginator = void 0;
var Ref_1 = require("../../Core/Ref");
var BasicPaginator_1 = require("./BasicPaginator");
var CollectionPaginator = /** @class */ (function (_super) {
    __extends(CollectionPaginator, _super);
    function CollectionPaginator(collectionView, _a) {
        var _this = this;
        var _b = _a === void 0 ? { perPage: [10, 50] } : _a, perPage = _b.perPage;
        var start = new Ref_1.RefNumber(0);
        var count = new Ref_1.RefNumber(perPage[0]);
        var total = collectionView.Collection.CountRef;
        var filtered = collectionView.FilteredCount;
        collectionView
            .See(start, count)
            .Refresh();
        _this = _super.call(this, start, count, total, filtered) || this;
        _this.AddGoToFirstButton();
        _this.AddGoToPreviousButton();
        _this.AddDisplay();
        _this.AddGoToNextButton();
        _this.AddGoToLastButton();
        _this.AddPagesCountSwitch(perPage);
        return _this;
    }
    return CollectionPaginator;
}(BasicPaginator_1.BasicPaginator));
exports.CollectionPaginator = CollectionPaginator;
