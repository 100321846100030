import { Div } from "@tblabs/truffle";


export class Arrow extends Div
{
    constructor()
    {
        super("arrow");
    }
}
